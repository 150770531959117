import { UPDATE, CREATE, DELETE } from 'admin-on-rest';
import { GET_LIST } from '../aor-loopback/types';

export const VIDEO_PLAYLIST_REORDER = 'VIDEO_PLAYLIST_REORDER';
export const videoPlaylistReorder = (id, data, basePath) => ({
    type: VIDEO_PLAYLIST_REORDER,
    payload: { id, data: data },
    meta: { resource: 'videoplaylists', fetch: UPDATE, cancelPrevious: false },
});

export const VIDEO_PLAYLIST_ADD = 'VIDEO_PLAYLIST_ADD';
export const videoPlaylistAdd = (data, basePath) => ({
    type: VIDEO_PLAYLIST_ADD,
    payload: { data: data },
    meta: { resource: 'videoplaylists', fetch: CREATE, cancelPrevious: false },
});

export const VIDEO_PLAYLIST_DELETE = 'VIDEO_PLAYLIST_DELETE';
export const videoPlaylistDelete = (id, playlistId, basePath) => ({
    type: VIDEO_PLAYLIST_DELETE,
    payload: { id },
    meta: { 
        playlistId, // Needed to be able to refresh it once item is deleted
        resource: 'videoplaylists', fetch: DELETE, cancelPrevious: false },
});
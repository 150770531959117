import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import {cyan500, grey500} from 'material-ui/styles/colors';
import ArrowUpward from 'material-ui/svg-icons/navigation/arrow-upward';

const UpButton = ({ disabled, action, record, resource, ...params }) => {

    console.log('upButton record', params)
    if (disabled) return <IconButton
        style={{ overflow: 'inherit' }}
    >
        <ArrowUpward  color={grey500}/>
    </IconButton>

    return <IconButton
        style={{ overflow: 'inherit' }}
        onClick={action(record)}
    >
        <ArrowUpward color={cyan500} />
    </IconButton>
    }

UpButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
};

UpButton.defaultProps = {
    style: { padding: 0 },
};

export default UpButton;

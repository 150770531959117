import React, { useState, useEffect } from 'react'
import { RaisedButton } from 'material-ui'
import ActionHourglassEmpty from 'material-ui/svg-icons/action/hourglass-empty';
import { confirmationModal } from '../confirmationmodal/confirm';

import {
    Datagrid,
    DateField,
    Delete,
    Edit,
    Filter,
    FormTab,
    List,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    DateInput,
    TextInput,
    NumberInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanInput,
    email,
    required,
    minLength
} from 'admin-on-rest'

import EditButton from '../buttons/EditButton'
import FullNameField from './FullNameField'
import FullAccountField from './FullAccountField'
import { DayOfWeekField } from '../stateless/DayOfWeek'

import EmbeddedChipFields from '../stateless/EmbeddedChipFields'
import CopyPlaylists from './CopyPlaylists'
import UploadVideos from './UploadVideos'
import { fetchJson } from '../aor-loopback/fetch'
import { Env } from '../env';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom du médecin" source="accountNameSearch" alwaysOn />
    </Filter>
);

export const AccountList = (props) => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }} perPage={25} filters={<PostFilter />}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <FullNameField />
            <TextField source="email" />
            <ReferenceField source="specialityId" perPage={500} reference="specialities" label="Spécialité" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

export const AccountCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" validate={[required, email]} />
            <ReferenceInput source="specialityId" perPage={500} reference="specialities" allowEmpty validate={[required]}>
                <SelectInput key='specialityId' optionText="name" />
            </ReferenceInput>
            <TextInput source="firstName" />
            <TextInput source="lastName" validate={[required, minLength(2)]} />
            <BooleanInput source="multiDoctor" />
            <TextInput source="multiDoctorNames" />
            <TextInput source="medicalCenterName" />
            <TextInput source="logo" />

            <br /><hr /><br />
            <h3>Données du profil</h3>

            <DateInput source="birthdate" />
            <TextInput source="companynumber" />
            <TextInput source="companyname" />
            <TextInput source="postaladdress" />
            <TextInput source="phonenumber" />
            <TextInput source="appointmenturl" />
            <TextInput source="otherurl" />
            <TextInput source="subdomain" validate={[required, minLength(2)]} />
            <TextInput source="wantRedirected" />

            <br /><hr /><br />
            <h3>Statut du compte</h3>

            <TextField source="videoGenerateStatus" />
            <BooleanInput source="isConfigured" />
            <BooleanInput source="isBlocked" />
            <BooleanInput source="adsEnabled" />
            <NumberInput source="adsPerHour" />
            <TextInput source="categoriesAlwaysVisible" />
            <BooleanInput source="isSubscribed" />
            <BooleanInput label="Ne pas générer pptx et vidéos" source="skipPptAndVideoGeneration" />
            <TextInput label="Choisir le répertoire où sont stockés les PPTX" source="pptxDirectoryPath" />

            <br /><hr /><br />
            <h3>Paiement</h3>

            <TextInput source="plan" />
            <NumberInput source="planPrice" />
            <TextInput source="planFrequency" />
            <TextInput source="stripeId" />
            <TextInput source="stripeCurrentPeriodEnd" />
            <TextInput source="stripeSubscriptionStatus" />

        </SimpleForm>
    </Create>
);

export class AccountEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            videoGenerateStatusPending: false
        }

    }
    componentDidMount() {
        this.getSelectedUserVideoPending();
        clearInterval(this.timerVideoGeneratingProgress);
        this.timerVideoGeneratingProgress = setInterval(() => {
            this.getSelectedUserVideoPending();
        }, 30000);
    }
    componentWillUnmount() {
        clearInterval(this.timerVideoGeneratingProgress);
    }

    generateVideos() {
        const { match: { params } } = this.props;
        let userId = params.id
        fetchJson(`${Env.API_URL}/Accounts/${userId}/regenerateVideos`, { method: 'POST' })
            .then(result => {
                if (result && result.status == 200)
                    this.setState({ videoGenerateStatusPending: true });
                console.log('info', result)
            })
            .catch(console.error.bind(this))
    }
    forceVideoGeneration() {
        if (this.state.videoGenerateStatusPending) {
            confirmationModal(`Une tache de génération de vidéos est déjà en cours. Voulez-vous quand même lancer celle-ci?`, { cancelLabel: "Annuler", okLabbel: "Oui", title: "Regénérer les videos" }).then(() => {
                this.generateVideos();
            }, () => { });
        } else {
            this.generateVideos();
        }

    }


    async getSelectedUserVideoPending() {
        const { match: { params } } = this.props;
        let userId = params.id;
        try {
            let result = await fetchJson(`${Env.API_URL}/Accounts/${userId}`, { method: 'GET' });
            this.setState({ videoGenerateStatusPending: (result.json && result.json.videoGenerateStatus && result.json.videoGenerateStatus == "pending") ? true : false })
        } catch (e) {
            console.log('error', e);
        }
    }

    render() {
        console.log(this.props)
        return (
            <Edit {...this.props}>
                <TabbedForm>
                    <FormTab label="resources.accounts.tabs.identity">
                        <h3>Identité du médecin / des médecins du cabinet</h3>
                        <TextInput source="email" validate={[required, email]} />
                        <ReferenceInput source="specialityId" perPage={500} reference="specialities" allowEmpty validate={[required]}>
                            <SelectInput key='specialityId' optionText="name" />
                        </ReferenceInput>
                        <TextInput source="firstName" />
                        <TextInput source="lastName" validate={[required, minLength(2)]} />
                        <BooleanInput source="multiDoctor" />
                        <TextInput source="multiDoctorNames" />
                        <TextInput source="medicalCenterName" />
                        <TextInput source="logo" />
                        <TextInput source="plan" />

                        <br /><hr /><br />
                        <h3>Données du profil</h3>

                        <DateInput source="birthdate" />
                        <TextInput source="companynumber" allowEmpty />
                        <TextInput source="companyname" />
                        <TextInput source="postaladdress" />
                        <TextInput source="phonenumber" />
                        <TextInput source="appointmenturl" />
                        <TextInput source="otherurl" />
                        <TextInput source="subdomain" validate={[required, minLength(2)]} />
                        <TextInput source="wantRedirected" />

                        <br /><hr /><br />
                        <h3>Statut du compte</h3>

                        <TextInput source="videoGenerateStatus" />
                        <BooleanInput source="isConfigured" />
                        <BooleanInput source="isBlocked" />
                        <BooleanInput source="adsEnabled" />
                        <NumberInput source="adsPerHour" />
                        <TextInput source="categoriesAlwaysVisible" />

                        <br /><hr /><br />
                        <h3>Paiement</h3>

                        <TextInput source="plan" />
                        <NumberInput source="planPrice" />
                        <TextInput source="planFrequency" />
                        <TextInput source="stripeId" />
                        <TextInput source="stripeCurrentPeriodEnd" />
                        <TextInput source="stripeSubscriptionStatus" />
                        <BooleanInput source="isSubscribed" />
                        <BooleanInput label="Ne pas générer pptx et vidéos" source="skipPptAndVideoGeneration" />
                        <TextInput label="Choisir le répertoire où sont stockés les PPTX" source="pptxDirectoryPath" />

                        <br /><hr /><br />
                        <h3>Playlists</h3>

                        <CopyPlaylists source="id" resource="playlists" />

                        <ReferenceManyField perPage={500} reference="playlists"
                            perPage={500}
                            sort={{ include: 'videos' }}
                            target="ownerId" label="Playlists">
                            <Datagrid>
                                <TextField source="title" />
                                <EmbeddedChipFields chipFieldSource="videoTitle" source="videos" />
                                <EditButton />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>

                    <FormTab label="Planifications">

                        <ReferenceManyField perPage={500} reference="plannings"
                            sort={{ field: 'dayOfWeek', order: 'ASC', include: 'playlist' }}
                            target="ownerId" label="Planifications">
                            <Datagrid>
                                <DayOfWeekField source="dayOfWeek" />
                                {/* <EmbeddedChipFields chipFieldSource="title" source="playlist"/> */}
                                <NumberField source="playlistId" />
                                <NumberField source="hourStart" />
                                <NumberField source="hourEnd" />
                                <EditButton />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>

                    <FormTab label="Vidéos">

                        <br />
                        <br />
                        <RaisedButton primary
                            icon={this.state.videoGenerateStatusPending ? <ActionHourglassEmpty className="Hourglass" /> : ''}
                            labelPosition="before" label="Regénérer les vidéos" onTouchTap={() => { this.forceVideoGeneration() }}
                        />
                        <br />
                        <br />

                        <UploadVideos />

                        <ReferenceManyField perPage={500} reference="videos"
                            sort={{ include: 'categories' }}
                            target="ownerId" label="Videos">
                            <Datagrid>
                                <TextField source="slug" />
                                {/* <TextField source="videoTitle" /> */}
                                <TextField source="file" />
                                <EmbeddedChipFields chipFieldSource="name" source="categories" />
                                <EditButton />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                </TabbedForm>
            </Edit>
        )
    }

}
//export const AccountDelete = (props) => <Delete {...props} title={<FullAccountField />} />;

// export const AccountEdit = (props) => { 
//     useEffect(() => {
//     });
//     return(
//         <Edit {...props}>
//             <TabbedForm>
//                 <FormTab label="resources.accounts.tabs.identity">
//                     <TextInput source="email" validate={[required, email]}/>
//                     <ReferenceInput source="specialityId" reference="specialities" allowEmpty validate={[required]}>
//                         <SelectInput key='specialityId' optionText="name" />
//                     </ReferenceInput>
//                     <TextInput source="firstName"  validate={[required, minLength(2)]}/>
//                     <TextInput source="lastName"  validate={[required, minLength(2)]}/>
//                     <TextInput source="subdomain"  validate={[required, minLength(2)]}/>
//                     <hr/>
//                     <BooleanInput source="multiDoctor" />
//                     <TextInput source="multiDoctorNames"/>
//                     <hr/>
//                     <BooleanInput source="isConfigured" />
//                     <BooleanInput source="isBlocked" />
//                     <BooleanInput source="adsEnabled" />
//                     <TextField source="stripeId" />
//                     <TextField source="stripeCurrentPeriodEnd" />
//                     <TextField source="stripeSubscriptionStatus" />

//                     <CopyPlaylists source="id" resource="playlists"/>

//                     <ReferenceManyField reference="playlists" 
//                     sort={{ include: 'videos' }}
//                     target="ownerId" label="Playlists">
//                         <Datagrid>
//                             <TextField source="title" />
//                             <EmbeddedChipFields chipFieldSource="videoTitle" source="videos"/>
//                             <EditButton />
//                         </Datagrid>
//                     </ReferenceManyField>
//                 </FormTab>

//                 <FormTab label="Planifications">

//                     <ReferenceManyField reference="plannings"
//                     sort={{ field: 'dayOfWeek', order: 'ASC', include: 'playlist' }}
//                     target="ownerId" label="Planifications">
//                         <Datagrid>
//                             <DayOfWeekField source="dayOfWeek" />
//                             <EmbeddedChipFields chipFieldSource="title" source="playlist"/>
//                             <NumberField source="hourStart" />
//                             <NumberField source="hourEnd" />
//                             <EditButton />
//                         </Datagrid>
//                     </ReferenceManyField>
//                 </FormTab>

//                 <FormTab label="Vidéos">

//                     <br/>
//                     <br/>
//                     <RaisedButton primary label="Regénérer les vidéos" onTouchTap={()=>{forceVideoGeneration(props)}}/>
//                     <br/>
//                     <br/>

//                     <UploadVideos />

//                     <ReferenceManyField reference="videos" 
//                     sort={{ include: 'categories' }}
//                     target="ownerId" label="Videos">
//                         <Datagrid>
//                             <TextField source="slug" />
//                             {/* <TextField source="videoTitle" /> */}
//                             <TextField source="file" />
//                             <EmbeddedChipFields chipFieldSource="name" source="categories"/>
//                             <EditButton />
//                         </Datagrid>
//                     </ReferenceManyField>
//                 </FormTab>
//             </TabbedForm>
//         </Edit>
//     )
// };


export const AccountDelete = (props) => <Delete {...props} title={<FullAccountField />} />;
import React from 'react'

import {
    Datagrid,
    DateField,
    Delete,
    Edit,
    Filter,
    List,
    TextField,
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanField,
    BooleanInput,
} from 'admin-on-rest'

import EditButton from '../buttons/EditButton'
import UploadVideos from './UploadVideos';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Titre de la vidéo" source="videoTitleSearch" alwaysOn />
        <TextInput label="Nom du médecin" source="videoOwnerNameSearch" alwaysOn />
        <ReferenceInput label="Category" source="categoryId" reference="categories" perPage={500}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const VideoList = (props) => (
    <List {...props} sort={{ field: 'videoTitle', order: 'ASC' }} perPage={25} filters={<PostFilter />}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <ReferenceField source="ownerId" reference="accounts" label="Compte" allowEmpty perPage={500}>
                <TextField source="email" />
            </ReferenceField>
            <TextField source="videoTitle" />
            <ReferenceField source="categoryId" reference="categories" label="Catégorie" allowEmpty perPage={500}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isTemplate" />
            <BooleanField label='is Ad ?' source="isAd" />
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

const SelectInputrender = (choice)=>`${choice.Speciality}-${choice.name}`;
export class VideoCreate extends React.Component {
    constructor(props) {
        super(props)
        this.onFileUploadSuccess = this.onFileUploadSuccess.bind(this)
    }
    onFileUploadSuccess(fileUrl) {
        console.log("this is", this)
        // apply the new file url to the field
        this
            .fields.file
            .handleInputBlur(fileUrl)
    }
    render() {
        let props = this.props
        let refOptions = {ref: (e) => {
            if (e && e.constructor && e.props && e.props.name) {
                this.fields = this.fields || {};
                this.fields[e.props.name] = e;
            }
        }}
        console.log('videocreate',props)
        return (
            <Create {...props}>
                <SimpleForm>
                    <TextInput source="videoTitle" />
                    <TextInput source="slug" validate={value => /[^a-zA-Z\d\s-]/g.test(value) ? 'Slug invalide' : undefined}/>
                    <TextInput source="description" />
                    {/* <NumberInput source="duration"/> */}
                    <UploadVideos onFileUploadSuccess={this.onFileUploadSuccess}/>
                    <TextInput
                        elStyle={{width:"550px", fontSize:"12px"}}
                        source="file"
                        options={refOptions}
                    />
                    <BooleanInput source="isTemplate" />
                    <BooleanInput label='is Ad ?' source="isAd" />
                    <ReferenceInput  label="Catégorie" source="categoryId"   reference="categories" allowEmpty perPage={500}>
                        <SelectInput  optionText={SelectInputrender} />
                    </ReferenceInput>
                    <ReferenceInput label="Compte" source="ownerId" reference="accounts" allowEmpty perPage={500}>
                        <SelectInput optionText="email" />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        )
    }
}

export class VideoEdit extends React.Component {
    constructor(props) {
        super(props)
        this.onFileUploadSuccess = this.onFileUploadSuccess.bind(this)
    }
    onFileUploadSuccess(fileUrl) {
        console.log("this is", this)
        // apply the new file url to the field
        this
            .fields.file
            .handleInputBlur(fileUrl)
    }
    render() {
        let props = this.props
        let refOptions = {ref: (e) => {
            if (e && e.constructor && e.props && e.props.name) {
                this.fields = this.fields || {};
                this.fields[e.props.name] = e;
            }
        }}
        console.log('videoedit',this.fields)
        return (
            <Edit {...props}>
                <SimpleForm>
                    <TextInput source="videoTitle" />
                    <TextInput source="slug" validate={value => /[^a-zA-Z\d\s-]/g.test(value) ? 'Slug invalide' : undefined}/>
                    {/* <NumberInput source="duration"/> */}
                    <TextInput source="description" />
                    <UploadVideos onFileUploadSuccess={this.onFileUploadSuccess}/>
                    <TextInput
                        elStyle={{width:"550px", fontSize:"12px"}}
                        source="file"
                        options={refOptions}
                    />
                    <BooleanInput source="isTemplate" />
                    <BooleanInput label='is Ad ?' source="isAd" />
                    <ReferenceInput label="Catégorie" source="categoryId" reference="categories" allowEmpty perPage={500}>
                        <SelectInput optionText="name" optionText={SelectInputrender}/>
                    </ReferenceInput>
                    <ReferenceInput label="Compte" source="ownerId" reference="accounts" allowEmpty perPage={500}>
                        <SelectInput optionText="email" />
                    </ReferenceInput>
                </SimpleForm>
            </Edit>
        )
    }
}

export const VideoDelete = (props) => <Delete {...props} videoTitle={record => <span>{record.videoTitle}</span>} />;
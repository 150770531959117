import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { UPDATE, showNotification, CREATE, crudGetManyReference } from 'admin-on-rest';

export default function* playlistSaga() {
    yield [
        takeEvery('CRUD_CREATE', crudCreate),
        takeEvery('VIDEO_PLAYLIST_DELETE_SUCCESS', videoPlaylistDelete)
    ];
}

function* crudCreate(data) {
    console.info('saga CREATE', data)
    let { requestPayload, meta } = data
    
    if (meta && meta.resource === 'videoplaylists') {
        console.log('! ! ! ! ! !')
    }
    //yield put(showNotification('resources.reviews.notification.approved_success'));
    // yield put(push('/reviews'));
}


function* videoPlaylistDelete(data) {
    console.info('saga DELETE', data)
    let { requestPayload, meta } = data
    
    if (meta && meta.resource === 'videoplaylists') {


        if (meta.playlistId) {
            console.log('triggered refresh ofp items of playlist ' + meta.playlistId)
            yield put(crudGetManyReference('videoplaylists', "playlistId", meta.playlistId, 
            `playlists_videoplaylists@playlistId_${meta.playlistId}`, 
            { page: 1, perPage: 25 }, 
            {
                include: "video",
                field: "order",
                order: "ASC"
            }, 
            {}));
        }
    }
    //yield put(showNotification('resources.reviews.notification.approved_success'));
    
}
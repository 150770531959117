import React, { Component } from 'react';
import { Admin, Delete, Resource } from 'admin-on-rest';

import './App.css';

import loopbackRestClient, {authClient} from './aor-loopback';
import sagas from './sagas';
import themeReducer from './themeReducer';
import plandata from './reducer';
import Login from './Login';
import Layout from './Layout';
import Menu from './Menu';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import translations from './i18n';

import { AccountList, AccountEdit, AccountCreate, AccountDelete } from './accounts'
import { SpecialityList, SpecialityEdit, SpecialityCreate, SpecialityDelete } from './specialities'
import { CategoryList, CategoryEdit, CategoryCreate, CategoryDelete } from './categories'
import { PlanningList, PlanningEdit, PlanningCreate, PlanningDelete } from './plannings'
import { PlaylistList, PlaylistEdit, PlaylistCreate, PlaylistDelete } from './playlists'
import { VideoList, VideoEdit, VideoCreate, VideoDelete } from './videos'
import { VideoPlaylistCreate } from './videoplaylists'
import { ChargeChange } from './changecharges'
import { Env } from './env';
class App extends Component {

    render() {
        return (
            <Admin
                title="Digidocteur"
               // restClient={loopbackRestClient('http://digidocteur.com:3001/api')} //('http://digidocteur.com:3001/api')}
                 restClient={loopbackRestClient(Env.API_URL + '')}   //('http://digidocteur.com:3001/api')}
                customReducers={{ theme: themeReducer ,plandetails:plandata}}
                customSagas={sagas}
                customRoutes={customRoutes}
              //  authClient={authClient('http://digidocteur.com:3001/api/Accounts/login?include=user')} //('http://digidocteur.com:3001/api/Accounts/login?include=user')}
                authClient={authClient(Env.API_URL + '/Accounts/login?include=user')}//('http://digidocteur.com:3001/api/Accounts/login?include=user')} 
                dashboard={Dashboard}
                locale="fr"
                loginPage={Login}
                appLayout={Layout}
                menu={Menu}
                messages={translations}
            >
                <Resource name="accounts" list={AccountList} edit={AccountEdit} create={AccountCreate} remove={AccountDelete} />
                <Resource name="specialities" list={SpecialityList} edit={SpecialityEdit} create={SpecialityCreate} remove={SpecialityDelete} />
                <Resource name="categories" list={CategoryList} edit={CategoryEdit} create={CategoryCreate} remove={CategoryDelete} />
                <Resource name="plannings" list={PlanningList} edit={PlanningEdit} create={PlanningCreate} remove={PlanningDelete} />
                <Resource name="playlists" list={PlaylistList} edit={PlaylistEdit} create={PlaylistCreate} remove={PlaylistDelete} />
                <Resource name="videos" list={VideoList} edit={VideoEdit} create={VideoCreate} remove={VideoDelete} />
                <Resource name="videoplaylists" create={VideoPlaylistCreate} />
            
            </Admin>
        );
    }
}

export default App;

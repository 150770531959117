import React from 'react'
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import Uploader from '../Uploader'
import {crudUpdate} from 'admin-on-rest'
import {fetchJson} from '../aor-loopback/fetch'

class UploadVideos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filesUploading: [],
      filesToUpload: [],
      errors: [],
      droppedOnce: false
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    // do stuff with files...
    console.log('key', this.props);

    let expectedSlugs = Object
      .keys(this.props.data)
      .map(key => {
        console.log('key', key);
        let video = this.props.data[key]
        return video.slug
      })

    if (acceptedFiles && acceptedFiles.length) {

      this.setState({droppedOnce: true})

      let files = []

      acceptedFiles.map(file => {

        let fileNameSplat = file
          .name
          .split(".")
        let format = fileNameSplat[fileNameSplat.length - 1]
        let fileName = fileNameSplat[0]

        var FR = new FileReader()
        FR.onload = (e) => {
          Uploader
            .upload(format, e.target.result)
            .then(videoUrl => {
              console.log('result for %s', fileName, videoUrl)
              let filesUploading = this.state.filesUploading.slice()
              filesUploading.splice(filesUploading.indexOf(fileName), 1)
              this.setState({ filesUploading })
              this
                .props
                .onFileUploadSuccess(videoUrl)
            })
            .catch((e) => {
              this.setState({
                errors: this
                  .state
                  .errors
                  .concat(`Erreur lors de l'upload`)
              })
            })
        }

        FR.readAsArrayBuffer(file)
        files.push(fileName)
      })

      this.setState({
        filesUploading: this
          .state
          .filesUploading
          .concat(files),
        filesToUpload: this
          .state
          .filesToUpload
          .concat(files)
      })
    }
  }

  render() {

    let children = []

    if (this.state.filesUploading.length) {

      children.push(
        <div>Fichier vidéo en cours de chargement sur S3...</div>
      )
    } else {

      if (!this.state.filesToUpload.length) {

        children.push(
          <div>Glissez-déposez une vidéo, ou cliquez ici pour charger un fichier .mp4 sur S3</div>
        )
      } else {

        children.push(
          <div> Fichier vidéo chargé sur S3 </div>
        )
      }
    }

    if (this.state.errors.length) {

      children.push(this.state.errors.map(err => <div className="error">{err}</div>))
    }

    return <Dropzone
      onDrop={this
      .onDrop
      .bind(this)}
      disablePreview={true}
      children={children}
      style={{
      width: '100%',
      border: '2px dashed grey',
      padding: 20,
      boxSizing: 'border-box'
    }}/>
  }
}

function mapStateToProps(state, props) {
  const resourceState = state.admin.videos;
  console.log("state", state)
  return {
    params: resourceState.list.params,
    ids: resourceState.list.ids,
    total: resourceState.list.total,
    data: resourceState.data,
    isLoading: state.admin.loading > 0,
    filterValues: resourceState.list.params.filter
  };
}
export default connect(mapStateToProps, {crudUpdate})(UploadVideos)
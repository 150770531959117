import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import {cyan500} from 'material-ui/styles/colors';
import ArrowDownward from 'material-ui/svg-icons/navigation/arrow-downward';

const DownButton = ({ basePath = '', record = {}, resource, action }) => (
  <IconButton
        style={{ overflow: 'inherit' }}
        onClick={action(record)}
    >
        <ArrowDownward color={cyan500} />
    </IconButton>
);

DownButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
};

DownButton.defaultProps = {
    style: { padding: 0 },
};

export default DownButton;

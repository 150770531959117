import React from 'react'
import {
    Datagrid,
    DateField,
    Delete,
    Edit,
    List,
    TextField,
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanInput
} from 'admin-on-rest'

import EditButton from '../buttons/EditButton'

export const CategoryList = (props) => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }} perPage={50}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <ReferenceField source="specialityId" perPage={500} reference="specialities" label="Spécialité" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput label="Spécialité" source="specialityId" perPage={500} reference="specialities" allowEmpty>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="hidden" />
        </SimpleForm>
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput label="Spécialité" source="specialityId" perPage={500} reference="specialities">
              <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="hidden" />
        </SimpleForm>
    </Edit>
);

export const CategoryDelete = (props) => <Delete {...props} title={record => <span>{record.name}</span>} />;
import React from 'react';
import pure from 'recompose/pure';

const FullNameField = ({ record = {} }) => <span>
    {record.firstName} {record.lastName}
</span>;


const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'lastName',
    label: 'Nom',
};

export default PureFullNameField;

import React from 'react'
import {
    Datagrid,
    DateField,
    DateInput,
    Delete,
    Edit,
    Filter,
    FormTab,
    List,
    LongTextInput,
    NullableBooleanInput,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
    AutocompleteInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    NumberInput,
    Toolbar
} from 'admin-on-rest'
import { RaisedButton } from 'material-ui';
import loopbackRestClient from '../aor-loopback';
import { Env } from '../env';
const restClient = loopbackRestClient(Env.API_URL + '')

const VideoPlaylistCreateToolbar = props => <Toolbar {...props} >
  <RaisedButton primary label="Enregistrer" redirect="show" submitOnEnter={true} />
</Toolbar>;


export const VideoPlaylistCreate = (props) => {

  let defaultPlaylist = getQueryVariable('playlist', props.location.search)
   let Id= getQueryVariable('owner', props.location.search)
  return (
    <Create {...props} title="Ajouter une vidéo à une playlist">
        <SimpleForm defaultValue={{
          playlistId: defaultPlaylist,
          order: getQueryVariable('order', props.location.search) || 0
        }} redirect={`/playlists/${defaultPlaylist}`}>
          
          <ReferenceInput label="Playlist" source="playlistId" filter={{id:defaultPlaylist}} reference="playlists" perPage={500} allowEmpty>
          <AutocompleteInput optionText="title" />
          </ReferenceInput>
            
            <ReferenceInput label="Vidéo" source="videoId" filter={{ownerId:Id}} perPage={500} reference="videos" allowEmpty>
                <SelectInput optionText="videoTitle" />
          </ReferenceInput>

          <NumberInput source="order"/>
        </SimpleForm>
    </Create>)
}

function getQueryVariable(variable, query) {
    var query = query.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}
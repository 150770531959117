import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IconButton from 'material-ui/IconButton';
import {cyan500, grey500} from 'material-ui/styles/colors';
import ArrowUpward from 'material-ui/svg-icons/navigation/arrow-upward';
import ArrowDownward from 'material-ui/svg-icons/navigation/arrow-downward';

import { videoPlaylistReorder as videoPlaylistReorderAction } from '../playlists/playlistActions';

class ReorderButton extends Component {

  constructor(props) {
    super(props)

    this.findItemByOrder = this.findItemByOrder.bind(this)
    this.isLastItem = this.isLastItem.bind(this)
    this.handleClickDown = this.handleClickDown.bind(this)
    this.handleClickUp = this.handleClickUp.bind(this)
    this.reorderItem = this.reorderItem.bind(this)
  }

  findItemByOrder(order) {

    let found = null
    let data = this.props.parentProps.data
    let ids = Object.keys(data)

    for (var i = 0; i<ids.length; i++) {
      let id = ids[i]
      if (data[id]['order'] === order) {
        console.info('found item!', data[id])
        return data[id]
      }
    }

    return null
  }

  isLastItem(order) {
    return !this.findItemByOrder(order + 1)
  }

  handleClickUp(){
    const { videoPlaylistReorder, record } = this.props;
    
    let currentOrder = record.order
    let nextOrder = currentOrder - 1

    let otherItem = this.findItemByOrder(nextOrder)

    console.log('otherItem?', otherItem)
    if (otherItem) {
      this.reorderItem(otherItem.id, currentOrder)
    }
    
    this.reorderItem(record.id, nextOrder)
  }

  handleClickDown() {
    const { videoPlaylistReorder, record } = this.props;
    
    let currentOrder = record.order
    let nextOrder = currentOrder + 1

    let otherItem = this.findItemByOrder(nextOrder)
    if (otherItem) {
      this.reorderItem(otherItem.id, currentOrder)
    }
    
    this.reorderItem(record.id, nextOrder)
  }

  reorderItem(id, newPosition) {
    this.props.videoPlaylistReorder(id, {
      order: newPosition
    })
  }

  render() {
    const { record, isUp} = this.props

    if (isUp) {

      if (record.order === 0) {
        return <IconButton>
          <ArrowUpward color={grey500}/>
        </IconButton>
      } else {
        return <IconButton onTouchTap={this.handleClickUp}>
          <ArrowUpward color={cyan500}/>
        </IconButton>
      }
    } else {
      if (this.isLastItem(record.order)) {
        
        return <IconButton>
          <ArrowDownward color={grey500}/>
        </IconButton>
      } else {

        return <IconButton onTouchTap={this.handleClickDown}>
          <ArrowDownward color={cyan500}/>
        </IconButton>
      }
    }
  }
}

ReorderButton.propTypes = {
  videoPlaylistReorder: PropTypes.func,
  record: PropTypes.object,
};

export default connect(null, {
    videoPlaylistReorder: videoPlaylistReorderAction,
})(ReorderButton);
//import storage from './storage'
import { put, call, takeLatest, fetch, takeEvery } from 'redux-saga/effects';
//import { flashMessage } from 'redux-flash';
import axios from 'axios';
import { push } from 'react-router-redux'
import { UPDATE_REQUEST, successupdate, errorupdate, PLAN_REQUEST, sucessplancharge } from './actions';
import { Env } from '../env';

export default function* palnChargeSaga() {
  yield [
    takeEvery(PLAN_REQUEST, getplancharge),
    takeEvery(UPDATE_REQUEST, requestupdate1)
  ];
}


function* getplancharge() {
  let response;
  try {
    var session = JSON.parse(localStorage.getItem("vp-session"))
    var token = session.value.id
    var userId = session.value.userId
    console.log('hdhd');
    response = yield call(axios.get, `${Env.API_URL}/subscriptionPlans`, { headers: { Authorization: token } });
  } catch (err) {
    let error;
    if (err.response && (err.response.status === 401 || err.response.status === 422)) {
      error = 'something is wrong..!';
      console.log(error);
    }
    return;
  }
 // console.log(response.data);
  yield put(sucessplancharge(response.data));
  yield put(push('/changecharges'));
}
// export default function* watchSaveCardRequest() {
//   yield takeLatest(PLAN_REQUEST,getplancharge );
// }
function* requestupdate1(action) {
  // console.log('in card Add request');


  let response;
  try {
    var session = JSON.parse(localStorage.getItem("vp-session"))
    var token = session.value.id
    var userId = session.value.userId
    var { id, yearlyAmount, monthlyAmount, currency } = action.data;
    response = yield call(axios.put, `${Env.API_URL}/subscriptionPlans/` + id, { yearlyAmount, monthlyAmount, currency }, { headers: { Authorization: token } });
  } catch (err) {
    let error;
    if (err.response && (err.response.status === 401 || err.response.status === 422)) {
      error = 'something is wrong..!';
      yield put(errorupdate(error));

      // yield put(flashMessage('authorization required'),{ timeout: 5000 });
    }
    return;
  }


 // console.log('aaaaaaaaaaaaa', JSON.stringify(response.data));
  yield put(successupdate(response.data));
  yield put(push('/changecharges'));
  //  yield put(flashMessage("Carte Deatils Ajouter avec succès",{ timeout: 5000 }));
}
// export default function* watchSaveCardRequest() {
//   yield takeLatest(PLAN_REQUEST,getplancharge );
//   yield takeLatest(UPDATE_REQUEST,requestupdate);  
// }


// function* logoutRequest() {
//   clearAuthData();

//   yield put(logoutSuccess());
// }

// export function* watchLogoutRequest() {
//   yield takeLatest(LOGOUT_REQUEST, logoutRequest);
// }

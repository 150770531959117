export default {
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items: 'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
            },
            welcome: {
                title: 'Bienvenue sur la démo d\'admin-on-rest',
                subtitle: 'Ceci est le back-office d\'un magasin de posters imaginaire. N\'hésitez pas à explorer et à modifier les données. La démo s\'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.',
                aor_button: 'Site web d\'admin-on-rest',
                demo_button: 'Code source de cette démo',
            },
        },
    },
    resources: {
        videos: {
            name: 'Vidéo |||| Vidéos',
            fields: {
                id: 'N°',
                videoTitle: 'Titre',
                file: 'Url du fichier vidéo',
                duration: 'Durée en secondes',
                slug: 'Slug',
                isTemplate: 'Est un modèle ?',
                description: 'La description'
            }
        },
        accounts: {
            name: 'Compte |||| Comptes',
            fields: {
                email: 'Email',
                firstName: 'Prénom',
                lastName: 'Nom',
                multiDoctor: 'Multipraticien ?',
                multiDoctorNames: 'Noms des médecins (si multi)',
                subdomain: 'Sous-domaine',
                birthdate: 'Date de naissance',
                companynumber: 'Numero de siret',
                companyname: 'Nom de la société',
                postaladdress: 'Adresse postale',
                phonenumber: 'Numero de telephone',
                appointmenturl: 'URL site web rendez-vous',
                otherurl: 'URL autre site web',
                videoGenerateStatus: 'Statut de génération vidéos',
                plan: 'Plan',
                planPrice: 'Montant paiement récurrent',
                planFrequency: 'Fréquence de paiement',
                specialityId: 'Spécialité',
                adsEnabled: 'Publicités activées ?',
                adsPerHour: 'Nombre de publicités par heure (approximatif)',
                isConfigured: 'Est configuré ?',
                categoriesAlwaysVisible: 'Noms des catégories cachées visibles (séparées par des espaces)',
                isBlocked: 'Est bloqué ?',
                stripeId: 'ID Stripe',
                stripeCurrentPeriodEnd: 'Fin de période Stripe',
                stripeSubscriptionStatus: 'Status de souscription Stripe',
                isSubscribed: 'Client paye ?',
                wantRedirected: 'Rediriger la webapp vers le site client',
                created: 'Date de création',
                modified: 'Date de modification'
            },
            tabs: {
                identity: 'Identité',
                address: 'Adresse',
                orders: 'Commandes',
                reviews: 'Commentaires',
                stats: 'Statistiques',
            },
            page: {
                delete: 'Supprimer le client',
            },
        },
        plannings: {
            name: 'Planification |||| Planifications',
            fields: {
                hourStart: 'Heure de début',
                hourEnd: 'Heure de fin',
                dayOfWeek: 'Jour de la semaine',
                created: 'Date de création',
                modified: 'Date de modification'
            },
            page: {
                delete: 'Supprimer le client',
            },
        },
        commands: {
            name: 'Commande |||| Commandes',
            fields: {
                basket: {
                    delivery: 'Frais de livraison',
                    reference: 'Référence',
                    quantity: 'Quantité',
                    sum: 'Sous-total',
                    tax_rate: 'TVA',
                    total: 'Total',
                    unit_price: 'P.U.',
                },
                customer_id: 'Client',
                date_gte: 'Passées depuis',
                date_lte: 'Passées avant',
                nb_items: 'Nb articles',
                reference: 'Référence',
                returned: 'Annulée',
                status: 'Etat',
                total_gte: 'Montant minimum',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Catégorie',
                height_gte: 'Hauteur mini',
                height_lte: 'Hauteur maxi',
                height: 'Hauteur',
                image: 'Photo',
                price: 'Prix',
                reference: 'Référence',
                stock_lte: 'Stock faible',
                stock: 'Stock',
                thumbnail: 'Aperçu',
                width_gte: 'Largeur mini',
                width_lte: 'Margeur maxi',
                width: 'Largeur',
            },
            tabs: {
                image: 'Image',
                details: 'Détails',
                description: 'Description',
                reviews: 'Commentaires',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                hidden: 'Est caché par défaut ?',
                name: 'Nom',
                products: 'Produits',
            },
        },
        reviews: {
            name: 'Commentaire |||| Commentaires',
            fields: {
                customer_id: 'Client',
                command_id: 'Commande',
                product_id: 'Produit',
                date_gte: 'Publié depuis',
                date_lte: 'Publié avant',
                date: 'Date',
                comment: 'Texte',
                status: 'Statut',
                rating: 'Classement',
            },
            action: {
                accept: 'Accepter',
                reject: 'Rejeter',
            },
            notification: {
                approved_success: 'Commentaire approuvé',
                approved_error: 'Erreur: Commentaire non approuvé',
                rejected_success: 'Commentaire rejeté',
                rejected_error: 'Erreur: Commentaire non rejeté',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom',
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur',
            },
        },
        
    },
};

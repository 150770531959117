import { Env } from "./env"

// Singleton - Manages file uploads to S3
let instance = null

class Uploader{
  constructor() {
    if(!instance){
      instance = this
    }
    return instance
  }

  _getUploadPolicy(format) {
    try{
      var session = JSON.parse(localStorage.getItem("vp-session"))
      var token = session.value.id
      var userId = session.value.userId
      return fetch(Env.API_URL + '/accounts/' + userId + '/getUploadPolicy?format=' + format + '&access_token=' + token)
    } catch(e){
      console.error("_getUploadPolicy error", e)            
    }
  }

  _uploadBlobToS3(blob, policy) {
    console.log("asdds",blob);
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      console.info('PUTting:', policy.signed_request, policy)
      xhr.open("PUT", policy.signed_request);
      xhr.setRequestHeader('x-amz-acl', 'public-read');
      xhr.onload = function() {
        if (xhr.status === 200) {
          console.log("url++++++++++++",policy.url)
          resolve(policy.url)
        } else {
          reject(xhr.status)
        }
      };
      xhr.onerror = function() {
        reject("Could not upload file.")
      };
      xhr.send(blob);
    })
  }

  upload(format, blob) {
    console.log("formate",format)
    return this._getUploadPolicy(format).then((response)=>{
      return response.json().then((response)=>{
        let policy = response
        console.log('response',response)
        return this._uploadBlobToS3(blob, policy)
      })
    })
  }
}

export default new Uploader()
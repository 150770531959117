export const CHANGE_THEME = 'CHANGE_THEME';
export const UPDATE_REQUEST='UPDATE_REQUEST';
export const UPDATE_SUCCESS='UPDATE_SUCCESS';
export const UPDATE_ERROR='UPDATE_ERROR';
export const PLAN_REQUEST='PLAN_REQUEST';
export const PLAN_SUCCESS='PLAN_SUCCESS';
export const PLAN_ERROR='PLAN_ERROR';

const initialstate={
    requesting:false,
    plandetails:{yearlyAmount:' ',monthlyAmount:' '}, 
}
export const getplancharge= ()=>(
    console.log('+++++'+initialstate.plandetails),
    {
    type:PLAN_REQUEST,
    data:initialstate.plandetails,
});
export const sucessplancharge= (token)=>(
    {
    type:PLAN_SUCCESS,
    data:token,
});
export const errorplancharge= (error)=>({
    type:PLAN_REQUEST,
    error:error,
});
export const requestupdate =(token) =>({
 type:UPDATE_REQUEST,
 data:token,
});
export const successupdate =(token) =>({
    type:UPDATE_SUCCESS,
    data:token,
   });
 export const errorupdate =(error) =>({
    type:UPDATE_ERROR,
    error:error,
   });
 
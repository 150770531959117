import React from 'react'
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui'
import loopbackRestClient from '../aor-loopback';
import { UPDATE, crudDelete } from 'admin-on-rest'
import ReorderButton from '../stateless/ReorderButton'
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';

import { IconButton, RaisedButton } from 'material-ui';
import { cyan500 } from 'material-ui/styles/colors';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionEdit from 'material-ui/svg-icons/image/edit';
import ActionAdd from 'material-ui/svg-icons/content/add';
import { Env } from '../env';
//import restClient from '../restClient';

const orderUp = () => { }

const restClient = loopbackRestClient(Env.API_URL + '')

const renderReorderableItem = (item, parentProps) => (<TableRow key={item.id}>
  <TableRowColumn>{item.video.videoTitle}</TableRowColumn>
  <TableRowColumn>
    <ReorderButton record={item} isUp={true} parentProps={parentProps} />
    <ReorderButton record={item} parentProps={parentProps} />
    <IconButton onTouchTap={() => {
      parentProps.push('/videos/' + item.videoId)
    }}>
      <ActionEdit color={cyan500} />
    </IconButton>
    <IconButton onTouchTap={() => {
      console.log('loc', parentProps.itemDelete(item.id, item.playlistId))
    }}>
      <ActionDelete color={cyan500} />
    </IconButton>
  </TableRowColumn>
</TableRow>)

class Reorderable extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {

    let addItem = function () {
      console.log("additem")
      let hashSplat = window.location.hash.split('#/playlists/')
      let playlistId = hashSplat[1]
      console.log("additem", playlistId)

      if (playlistId) {
        console.log('pushing ' + '/videoplaylists/create/?playlist=' + playlistId)
        restClient('GET_ONE', 'playlists', {
          id: playlistId
        })
          .then(response => {
            console.log('11111111111111111111111111', response)

            props.push(`/videoplaylists/create/?order=${nextOrder}&playlist=${playlistId}&owner=${response.data.ownerId}`)
          }
          )
          .catch(error => console.log('222222222222222222222', error))
      }
    }

    let props = this.props
    let record = props.record
    let source = props.source
    let data = props.data
    console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwvvvvvvvvvvvvvvvvvv", props);
    if (!data) return <TableRow key="new">
      <TableRowColumn></TableRowColumn>
      <TableRowColumn>
        <RaisedButton primary label="Ajouter une vidéo" icon={<ActionAdd />} onTouchTap={addItem} />
      </TableRowColumn>
    </TableRow>

    let ids = Object.keys(data)

    if (!ids.length) return <TableRow key="new">
      <TableRowColumn></TableRowColumn>
      <TableRowColumn>
        <RaisedButton primary label="Ajouter une vidéo" icon={<ActionAdd />} onTouchTap={addItem} />
      </TableRowColumn>
    </TableRow>

    let items = []
    let ordersTaken = []
    let orderExistsMultipleTimes = false

    ids.forEach(id => {
      items.push(data[id])

      if (ordersTaken.indexOf(data[id]['order']) != -1) {
        orderExistsMultipleTimes = data[id]['order']
      } else {
        ordersTaken.push(data[id]['order'])
        ordersTaken.sort()
      }
    })

    items.sort((a, b) => a.order > b.order)
    let tableRows = []
    items.forEach((item, i) => {
      if (item && item.video) tableRows.push(renderReorderableItem(item, props))
    })

    let warning = <p></p>

    if (orderExistsMultipleTimes) {
      warning = <p>Attention, plusieurs éléments sont à la même position.</p>
    }

    let lastOrder = ordersTaken[ordersTaken.length - 1]
    let nextOrder = (lastOrder) ? lastOrder + 1 : 0

    tableRows.push(<TableRow key="new">
      <TableRowColumn></TableRowColumn>
      <TableRowColumn>
        <RaisedButton primary label="Ajouter une vidéo" icon={<ActionAdd />} onTouchTap={addItem} />
      </TableRowColumn>
    </TableRow>)

    console.log('DATA', data, record)

    return <div>
      {warning}
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
          <TableRow>
            <TableHeaderColumn>Titre de la vidéo</TableHeaderColumn>
            <TableHeaderColumn>Actions</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {tableRows}
        </TableBody>
      </Table>
    </div>
  }
}

export default connect(null, {
  push: pushAction,
  crudDelete
})(Reorderable);
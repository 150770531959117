import React from 'react'
import {
    Datagrid,
    DateField,
    DateInput,
    Delete,
    Edit,
    Filter,
    FormTab,
    LongTextInput,
    NullableBooleanInput,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanField,
    BooleanInput,
    FunctionField,
    List,
    NumberInput,
    crudCreate as crudCreateAction,
    crudGetOne as crudGetOneAction,
    crudGetManyReference as crudGetManyReferenceAction
} from 'admin-on-rest'

import { connect } from 'react-redux';
import { FlatButton } from 'material-ui'

import Reorderable from '../stateless/Reorderable'

import EditButton from '../buttons/EditButton'
import DeleteButton from '../buttons/DeleteButton'
import UpButton from '../buttons/UpButton'
import DownButton from '../buttons/DownButton'

import {
    videoPlaylistAdd as videoPlaylistAddAction,
    videoPlaylistDelete as videoPlaylistDeleteAction
} from '../playlists/playlistActions';

import loopbackRestClient, { authClient } from '../aor-loopback';
import { Env } from '../env';
const restClient = loopbackRestClient(Env.API_URL + '')

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom du médecin" source="playlistOwnerNameSearch" alwaysOn />
    </Filter>
);

export const PlaylistList = (props) => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }} perPage={25} filters={<PostFilter />}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <ReferenceField source="ownerId" reference="accounts" label="Compte" allowEmpty  perPage={500}>
                <TextField source="email" />
            </ReferenceField>
            <TextField source="title" />
            <BooleanField source="isTemplate" />
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

export const PlaylistCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <BooleanInput source="isTemplate" />
            <ReferenceInput label="Compte" source="ownerId" reference="accounts" allowEmpty  perPage={500}>
                <SelectInput optionText="email" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

class PlaylistEditClass extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        let props = this.props
        return <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" />
                <BooleanInput source="isTemplate" />
                <ReferenceManyField perPage={500} reference="videoplaylists"
                    sort={{ include: 'video', field: 'order', order: 'ASC' }}
                    target="playlistId" label="Videos">
                    <Reorderable source="videos" itemDelete={props.videoPlaylistDelete} />
                </ReferenceManyField>
            </SimpleForm>
        </Edit>
    }
}

export const PlaylistEdit = connect(null, {
    videoPlaylistAdd: videoPlaylistAddAction,
    videoPlaylistDelete: videoPlaylistDeleteAction,
    crudGetManyReference: crudGetManyReferenceAction
})(PlaylistEditClass);

export const PlaylistDelete = (props) => <Delete {...props} title={record => <span>{record.title}</span>} />;
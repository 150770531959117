import {UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_ERROR,PLAN_REQUEST,PLAN_ERROR,PLAN_SUCCESS} from './changecharges/actions';

const initialState = {
  request: false,
    error: undefined,
    plandetails:{yearlyAmount:'',monthlyAmount:''}, 
 
};

const reducer= (state = initialState, action) => {
  switch (action.type) {
        case PLAN_REQUEST:{ 
            return {
              ...state,
              request:true,
            };
          }
        case PLAN_SUCCESS:{ 
            return {
              ...state,
            request:false,
            plandetails:action.data[0],
            };
          }
        case UPDATE_REQUEST:{ 
            return {
              ...state,
            request:true,
          };
         } 
        case UPDATE_SUCCESS:{ 
          return {
            ...state,
           request:false,
           plandetails:action.data,
           };
         } 
        case UPDATE_ERROR:{ 
            return {
              ...state,
             request:false,
             error:action.error,
             };
         } 
    default:
      return state;
  }
};

export default reducer;

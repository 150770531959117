import React from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import { translate, changeLocale as changeLocaleAction, ViewTitle, NumberInput } from 'admin-on-rest';
import { changeTheme as changeThemeAction } from './actions';
import EuroSymbol from 'material-ui-icons/EuroSymbol';
import { Edit, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'admin-on-rest';
import {getplancharge,requestupdate} from './actions';
const styles = {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em', marginLeft: '12em' ,marginTop: '1em'},
};
class ChangeCharges extends React.Component{
    static propTypes = {
        request:propTypes.bool,
        plandetails:propTypes.shape(
            {   yearlyAmount:propTypes.string,
                monthlyAmount:propTypes.string,
                id:propTypes.string,
                currency:propTypes.string,
            }
        ),
        requestupdate:propTypes.func,
        getplancharge:propTypes.func,
    }
    constructor()
    {
        super();
            this.state={isOpen:false,month:'',year:''};
        this.handleChangeyear=this.handleChangeyear.bind(this);
        this.handleChangemonth=this.handleChangemonth.bind(this);
        this.submit=this.submit.bind(this);
    }
    componentDidMount(){
        this.props.getplancharge();
      
    }
   
    submit(){
        console.log("$$$$$$$$$$###########");
        const m=this.state.month;
        const y=this.state.year;
        const {plandetails}=this.props.plandetails;
        const{id,currency}=plandetails;
        this.props.requestupdate({yearlyAmount:y,monthlyAmount:m,id:id,currency:currency});
        this.setState({month:'',year:''});
    }
    handleChangemonth(event){
        const target = event.target;
        const value = target.value
        this.setState({month:value});
    }
    handleChangeyear(event){
        const target = event.target;
        const value = target.value
         this.setState({year:value});
    }
    render(){
       
        const {plandetails}=this.props.plandetails;
        const {yearlyAmount,monthlyAmount}=plandetails;
       
        return(
            <Card>
            <ViewTitle title='Détails du plan actuel ' />
            <CardText>        
                        <div style={{float:'left', display:'inline-block',verticalAlign:'top' ,marginLeft: '25px'}}>Charge par mois:<b style={{marginLeft:'21px'}}>{monthlyAmount}</b> 
                           
                            <div style={{display:'inline-block',marginTop:'1px'}}>
                               <EuroSymbol style={{height:'15px',width:'15px',marginLeft:'1px'}}/>
                           </div>
                       </div>
                       </CardText>
                       <CardText>
                    
                        <div style={{float:'left', display:'inline-block',verticalAlign:'top' ,marginLeft: '25px'}}>Charge par an : <b style={{marginLeft:'29px'}}>{yearlyAmount}</b>
                         
                            <div style={{display:'inline-block'}}>
                                <EuroSymbol style={{height:'15px',width:'15px',marginLeft:'1px'}}/>
                            </div>    
                        </div>
                        </CardText>
                    <br/><br/> <hr/><br/>
                     <ViewTitle title='Mettre à jour le plan ' />
   
                     <CardText>
                        <div style={{float:'left', display:'inline-block',verticalAlign:'top' ,marginLeft: '25px'}}>Charge par mois : 
                            {/* {!this.state.isOpen && <b style={{marginLeft:'21px'}}>20</b> } */}
                       <input type='number' style={{marginLeft: '29px'}} value={this.state.month} onChange={this.handleChangemonth} />
                            <div style={{display:'inline-block'}}>
                               <EuroSymbol style={{height:'15px',width:'15px',marginLeft:'0px'}}/>
                           </div>
                       </div>
                       </CardText>
                       <CardText>
                    
                        <div style={{float:'left', display:'inline-block',verticalAlign:'top' ,marginLeft: '25px'}}>Charge par an : 
                            {/* {!this.state.isOpen && <b style={{marginLeft:'33px'}}>100</b>} */}
                             <input type='number' style={{marginLeft:'42px'}}  onChange={this.handleChangeyear} value={this.state.year}/>
                            <div style={{display:'inline-block'}}>
                                <EuroSymbol style={{height:'15px',width:'15px',marginLeft:'0px'}}/>
                            </div>    
                        </div>
                        </CardText>
                        <CardText>
                        <RaisedButton style={styles.button} label='update' primary onClick={this.submit} /> 
                        </CardText>
                 
                 
                
    
        </Card>
        );     
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
    plandetails:state.plandetails,
  
});

export default connect(mapStateToProps, {
    requestupdate,getplancharge
})(ChangeCharges);

import React from 'react'
import { ChipField } from 'admin-on-rest'

export default ({source, record={}, chipFieldSource}) => {

    let elements = record[source]
    let chips = []

    if (!elements || !elements.length) { return <span></span> }

    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        chips.push(<ChipField record={element} source={chipFieldSource} key={i}/>)
    }

    return <span>{chips}</span>
}
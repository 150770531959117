import storage from './storage'
// import { AUTH_GET_PERMISSIONS } from 'aor-permissions'
let accountTypes = {
    CLIENT:   0,
    ADVISOR:  50,
    ADMIN:    100
}

/**
 * @return {Promise}
 * @param {string} loginApiUrl 
 * @param {string} noAccessPage 
 */
export const authClient = (loginApiUrl, noAccessPage = '/login') => {
    console.log('ICI')
    return (type, params) => {

        if (type === 'AUTH_LOGIN') {
            const request = new Request(loginApiUrl, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        console.log('THELOG', response)
                        // let responseText = JSON.parse(response.json())
                        // debugger
                        return response.json().then(err => {
                            console.info('RESPONSE ERROR', err)
                            throw err.error
                        })
                    }
                    return response.json();
                })
                .then(({ ttl, ...data }) => {
                    storage.save('vp-session', data, ttl);
                    return data
                });
        }
        if (type === 'AUTH_LOGOUT') {
            storage.remove('vp-session');
            return Promise.resolve();
        }
        if (type === 'USER_LOGIN_FAILURE') {
            debugger
            return Promise.reject(params)
        }
        if (type === 'AUTH_ERROR') {
            const { status } = params;
            if (status === 401 || status === 403) {
                // storage.remove('vp-session');
                // return Promise.reject();
            }
            return Promise.resolve();
        }
        if (type === 'AUTH_CHECK') {
            const token = storage.load('vp-session');
            const { resource, route } = params;

            if (token && token.id) {

                return Promise.resolve()
            } else {

                storage.remove('vp-session');
                return Promise.reject({ redirectTo: noAccessPage });
            }
        }
        return Promise.reject('Unkown method');
    };
};
import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { change } from 'redux-form'
import slugify from 'slugify'

export default function* playlistSaga() {
    yield [
        takeEvery('@@redux-form/CHANGE', reduxFormChange),
    ];
}

function* reduxFormChange(data) {
    let { payload, meta } = data
    
    if (meta && meta.field === 'videoTitle') {
        let slug = slugify(payload, {
            lower: true,
            remove: /[^a-zA-Z\d\s]/g,
            replacement: '-'
        })

        yield put(change("record-form", "slug", slug))
    }
}
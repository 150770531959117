import React from 'react'
import { SelectInput, FunctionField } from 'admin-on-rest';

let weekDays = [
    'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
]

let weekDaysChoices = []
weekDays.forEach((name, id) => weekDaysChoices.push({id, name}))

export const DayOfWeekPicker = <SelectInput allowEmpty source="dayOfWeek"
    choices={weekDaysChoices} 
/>

export const DayOfWeekField = props => <FunctionField {...props} label="Jour de la semaine" 
    render={record => weekDays[record.dayOfWeek] || 'Indéfini'} 
/>

import React from 'react'
/* eslint no-restricted-globals: "warn" */
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import Uploader from '../Uploader'
import { crudUpdate } from 'admin-on-rest'
import { fetchJson } from '../aor-loopback/fetch'
import { Env } from '../env'

class UploadVideos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filesUploading: [],
      filesToUpload: [],
      errors: [],
      droppedOnce: false
    }
  }

  attachFileToVideo(videoSlug, videoUrl) {

    // find video id from slug
    let videoId = Object.keys(this.props.data)
      .find(id => this.props.data[id]['slug'] == videoSlug)
    console.log("id of videos",videoId);

    // update video with correct url
    let video = this.props.data[videoId]
    video.file = videoUrl

    
    console.log('dipatching update for video %s %s set url as %s', videoId, videoSlug, videoUrl, video)

    return fetchJson(Env.API_URL + '/videos/' + videoId, {
      method: 'put',
      body: JSON.stringify(video)
    })
    
    // this.props.crudUpdate('videos', videoId, { file: videoUrl })
  }

  onDrop(acceptedFiles, rejectedFiles) {
    // do stuff with files...
    console.log('key',this.props);
    
    let expectedSlugs = Object.keys(this.props.data).map(key => {
      console.log('key',key);
      let video = this.props.data[key]
      return video.slug
    })

    if (acceptedFiles && acceptedFiles.length) {

      this.setState({droppedOnce: true})

      let files = []
      
      acceptedFiles.map(file => {

        let fileNameSplat = file.name.split(".")
        let format = fileNameSplat[fileNameSplat.length - 1]
        let fileName = fileNameSplat[0]

        let correspondingSlugIndex = expectedSlugs.indexOf(fileName)

        if (correspondingSlugIndex == -1) {

          console.log('slug not found for %s', fileName)

          // this file doesnt match any slug
          this.setState({
            errors: this.state.errors.concat(`Aucun slug trouvé pour le fichier ${fileName}` )
          })

        } else {

          
          console.log('slug %s (%s) found for %s', expectedSlugs[correspondingSlugIndex], correspondingSlugIndex, fileName)

          var FR = new FileReader()
          FR.onload = (e) => {
            Uploader.upload(format, e.target.result)
            .then(videoUrl => {
              console.log('result for %s', fileName, videoUrl)

              // attach file to video
              return this.attachFileToVideo(fileName, videoUrl)
              .then(() => {
                  console.log("file update");
                // heavy manipulation because state is immutable in react. Helpers exist....
                let filesUploading = this.state.filesUploading.slice()
                filesUploading.splice(filesUploading.indexOf(fileName), 1)
                this.setState({ filesUploading })
              })
            })
            .catch(err => {
              console.log("file update error",err);
                
              this.setState({ errors: this.state.errors.concat(file.name) })
              console.error(err)
            })
          }
  
          FR.readAsArrayBuffer(file)
          files.push(fileName)
        }
      })

      this.setState({
        filesUploading: this.state.filesUploading.concat(files),
        filesToUpload: this.state.filesToUpload.concat(files)
      })
    }
  }
  
  render() {
    
    let children = []

    if (this.state.filesUploading.length) {

      children.push(<div>{this.state.filesToUpload.length - this.state.filesUploading.length}/{this.state.filesToUpload.length} vidéos uploadées...</div>)
    } else {

      if (!this.state.filesToUpload.length) {
        
        children.push(<div>Glissez-déposez les vidéos à uploader ici.</div>)
      } else {
        
        children.push(<div>{this.state.filesToUpload.length} vidéos ont été uploadées.
          <a href="#" onTouchTap={() => location.reload()}>Recharger</a></div>)
      }
    }

    if (this.state.errors.length) {

      children.push(this.state.errors.map(err => <div className="error">{err}</div>))
    }

    return <Dropzone onDrop={this.onDrop.bind(this)} disablePreview={true} children={children} style={{
      width: '100%',
      border: '2px dashed grey',
      padding: 20,
      boxSizing: 'border-box'
    }}/>
  }
}

function mapStateToProps(state, props) {
  const resourceState = state.admin.videos;
  return {
      params: resourceState.list.params,
      ids: resourceState.list.ids,
      total: resourceState.list.total,
      data: resourceState.data,
      isLoading: state.admin.loading > 0,
      filterValues: resourceState.list.params.filter,
  };
}

export default connect(mapStateToProps, { crudUpdate })(UploadVideos)
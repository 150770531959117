import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import MenuItem from 'material-ui/MenuItem';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import LabelIcon from 'material-ui/svg-icons/action/label';
import { translate, DashboardMenuItem } from 'admin-on-rest';

import IconSocialPerson from 'material-ui/svg-icons/social/person'
import IconHardwareTv from 'material-ui/svg-icons/hardware/tv'
import IconImageTime from 'material-ui/svg-icons/image/timer'
import IconAvPlaylistPlay from 'material-ui/svg-icons/av/playlist-play'
import IconAvMovie from 'material-ui/svg-icons/av/movie'
import IconFileFolderShared from 'material-ui/svg-icons/file/folder-shared'
import IconFileFolder from 'material-ui/svg-icons/file/folder'
import EuroSymbol from 'material-ui-icons/EuroSymbol';

const items = [
    { name: 'Comptes', route: 'accounts', icon: <IconSocialPerson /> },
    { name: 'Planifications', route: 'plannings', icon: <IconImageTime /> },
    { name: 'Playlists', route: 'playlists', icon: <IconAvPlaylistPlay /> },
    { name: 'Vidéos', route: 'videos', icon: <IconAvMovie /> },
    { name: 'Spécialités', route: 'specialities', icon: <IconFileFolderShared /> },
    { name: 'Catégories', route: 'categories', icon: <IconFileFolder /> },
    { name: 'Gestion des Tarifs', route: 'changecharges', icon: <EuroSymbol /> },
    
];

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
    },
};

const Menu = ({ onMenuTap, translate, logout }) => (
    <div style={styles.main}>
        <DashboardMenuItem onTouchTap={onMenuTap} />
        {items.map(item => (
            <MenuItem
                key={item.name}
                containerElement={<Link to={`/${item.route}`} />}
                primaryText={item.name}
                leftIcon={item.icon}
                onTouchTap={onMenuTap}
            />
        ))}
        {/* <MenuItem
            containerElement={<Link to="/configuration" />}
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onTouchTap={onMenuTap}
        /> */}
        {logout}
    </div>
);

const enhance = compose(
    connect(state => ({
        theme: state.theme,
        locale: state.locale,
    })),
    translate,
);

export default enhance(Menu);

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { crudGetList } from 'admin-on-rest'
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRowColumn, TableRow,
  RaisedButton} from 'material-ui'
import { PlaylistAdd, PlaylistFetch } from '../playlists/playlistActions'
import {fetchJson} from '../aor-loopback/fetch'
import { Env } from '../env'

class CopyPlaylists extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: []
    }

    this.handleCopy = this.handleCopy.bind(this)
    this.renderPlaylist = this.renderPlaylist.bind(this)
    this.handleRowSelection = this.handleRowSelection.bind(this)
  }

  componentWillMount() {
    const pagination = { page: 1, perPage: 1000 };
    this.props.crudGetList('playlists', pagination, 
      { field: 'title', order: 'DESC' }, 
      { isTemplate: true })
  }

  renderPlaylist(playlist) {

    return <TableRow key={playlist.id}>
      <TableRowColumn>{playlist.title} - {playlist.ownerId}</TableRowColumn>
    </TableRow>
  }

  handleRowSelection(selectedRows){
    this.setState({
      selected: selectedRows
    })

    console.log({selectedRows})
  }

  handleCopy() {

    let selectedVideosIds = []

    if (this.state.selected == 'all') {

      selectedVideosIds = Object.keys(this.props.data)

    } else {

      let videosIds = Object.keys(this.props.data)
      
      selectedVideosIds = this.state.selected.map(i => videosIds[i])
  
    }
    
    console.log({selectedVideosIds})

    if (selectedVideosIds.length) {

      fetchJson(`${Env.API_URL}/Accounts/copyPlaylists`, { method: 'POST', body: JSON.stringify({
        ids: selectedVideosIds,
        uid: this.props.record.id
      })})
      .then(result => {

        console.log('info', result)
      })
      .catch(console.error.bind(this))
    }

  }

  render() {

    let data = []
    
    for (let i of Object.keys(this.props.data)) {

      let el = this.props.data[i]
      if (el.isTemplate) data.push(el)
    }

    let ids = Object.keys(data)
    console.info('props', this.props)
    if (!data) return <span>Chargement...</span>

    let playlists = ids.map(id => this.renderPlaylist(data[id]))

    console.info('playlists', playlists, this.props)
    return <div>
      <Table enableSelectAll={true} multiSelectable={true} onRowSelection={this.handleRowSelection}>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn colSpan="1" style={{textAlign: 'center'}}>
                Copier des playlists à partir des modèles de cette spécialité {this.props.loading}
            </TableHeaderColumn>
          </TableRow>
          <TableRow>
            <TableHeaderColumn>Titre de la playlist</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {playlists}
        </TableBody>
      </Table>
      <RaisedButton primary label="Copier les playlists sélectionnées" onTouchTap={this.handleCopy}/>
    </div>
  }
}

CopyPlaylists.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    crudGetList: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const resourceState = state.admin.playlists;
  return {
      params: resourceState.list.params,
      ids: resourceState.list.ids,
      total: resourceState.list.total,
      data: resourceState.data,
      isLoading: state.admin.loading > 0,
      filterValues: resourceState.list.params.filter,
  };
}

export default connect(mapStateToProps, { crudGetList })(CopyPlaylists);
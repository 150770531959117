import React from 'react'
import {
    Datagrid,
    DateField,
    DateInput,
    Delete,
    Edit,
    Filter,
    FormTab,
    List,
    LongTextInput,
    NullableBooleanInput,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
    Create,
    SimpleForm
} from 'admin-on-rest'

import EditButton from '../buttons/EditButton'
import FullAccountField from '../accounts/FullAccountField'

export const SpecialityList = (props) => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }} perPage={25}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <TextField source="name" />
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

export const SpecialityCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);

export const SpecialityEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceManyField perPage={500} reference="categories" target="specialityId" label="Catégories"  perPage={500}>
                <Datagrid>
                    <TextField source="name" />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
            <ReferenceManyField perPage={500} reference="accounts" target="specialityId" label="Comptes"  perPage={500}>
                <Datagrid>
                    <FullAccountField />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
        </SimpleForm>
    </Edit>
);

export const SpecialityDelete = (props) => <Delete {...props} title={record => <span>{record.name}</span>} />;
import React from 'react'
import {
    Datagrid,
    DateField,
    DateInput,
    Delete,
    Edit,
    Filter,
    FormTab,
    List,
    LongTextInput,
    NullableBooleanInput,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanField,
    FunctionField,
    NumberInput
} from 'admin-on-rest'

import EditButton from '../buttons/EditButton'

let weekDays = [
    'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
]

let weekDaysChoices = []
weekDays.forEach((name, id) => weekDaysChoices.push({id, name, key: id}))

const DayOfWeekField = props => <FunctionField {...props} label="Jour de la semaine" 
  render={record => weekDays[record.dayOfWeek] || 'Indéfini'} 
  />

const PostFilter = (props) => (
    <Filter {...props}>
    <TextInput label="Nom du médecin" source="planningOwnerNameSearch" alwaysOn />
    </Filter>
);

export const PlanningList = (props) => (
    <List {...props} sort={{ field: 'dayOfWeek', order: 'ASC' }} perPage={25} filters={<PostFilter />}>
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <ReferenceField source="ownerId" reference="accounts" label="Compte" allowEmpty perPage={500}>
                <TextField source="email" />
            </ReferenceField>
            <ReferenceField source="playlistId" reference="playlists" label="PlaylistTitle" perPage={500} allowEmpty>
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField source="playlistId" reference="playlists" label="PlaylistId" perPage={500} allowEmpty>
                <TextField source="id" />
            </ReferenceField>
            <DayOfWeekField source="dayOfWeek" />
            <NumberField source="hourStart" />
            <NumberField source="hourEnd" />
            <EditButton />
        </Datagrid>
    </List>
);

export const PlanningCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput allowEmpty source="dayOfWeek" choices={weekDaysChoices} />
            <NumberInput source="hourStart" />
            <NumberInput source="hourEnd" />
            <ReferenceInput label="Compte" source="owner" reference="accounts" allowEmpty perPage={500}>
                <SelectInput optionText="email" />
            </ReferenceInput>
            <ReferenceInput label="Playlist" source="playlistId" reference="playlists" perPage={500} allowEmpty>
                <SelectInput optionText="title" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const PlanningEdit = (props) => {
    console.log("props",props)
    return (
    <Edit {...props}>
        <SimpleForm>        
            <SelectInput source="dayOfWeek" choices={weekDaysChoices} />
            <NumberInput source="hourStart" />
            <NumberInput source="hourEnd" />
            <ReferenceInput label="Compte" source="ownerId" reference="accounts" perPage={500}>
                <SelectInput optionText="email" />
            </ReferenceInput>
            <ReferenceInput label="Playlist" source="playlistId" reference="playlists" allowEmpty  perPage={500}>
                <SelectInput optionText="title" />
            </ReferenceInput>
            {/*test:
            <ReferenceInput filter={{ owner: record.id}} label="Playlist" source="playlistId" reference="playlists" allowEmpty  perPage={500}>
                <SelectInput optionText="title" />
            </ReferenceInput>*/}
        </SimpleForm>
    </Edit>)
};

export const PlanningDelete = (props) => <Delete {...props} title={record => <span>{record.id}</span>} />;


let getHoursArray = (index = 0, end = 23) => {
    let result = []

    for (var index = 0; index < end; index++) {
        
        result.push({
            id: index,
            name: index + 'h'
        })
    }

    return result
}
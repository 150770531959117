import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { confirmable } from 'react-confirm';
import Warning from 'material-ui/svg-icons/alert/warning';
import { yellowA700 } from 'material-ui/styles/colors';

class ConfirmationModal extends React.Component {
    render() {
      const muiTheme = getMuiTheme(theme);
      const { theme } = this.props;
      const iconStyles = {
        marginRight: 24,
        height: 80,
        width: 80

      };
      const containerStyle = {
        display: 'flex',
        alignItems: 'center'
      }
      const {
        okLabbel = 'Validate',
        cancelLabel = 'Cancel',
        title = '',
        confirmation,
        show,
        proceed,
        dismiss,
        cancel,
        enableEscape = true,
      } = this.props;
      const actions = [
        <FlatButton
          label={cancelLabel}
          primary={true}
          onTouchTap={cancel}
        />,
        <FlatButton
          label={okLabbel}
          primary={true}
          onTouchTap={proceed}
        />,
      ];

      return (
        <div className="static-modal">
          <MuiThemeProvider muiTheme={muiTheme}>
          <Dialog
            title={title}
            actions={actions}
            modal={false}
            open={show}
            //onRequestClose={this.handleClose}
          >
            <div style={containerStyle}>
              <Warning style={iconStyles} color={yellowA700} />
              {confirmation}
            </div>
          </Dialog>
          </MuiThemeProvider>
        </div>
      )
    }
  }
  
  ConfirmationModal.propTypes = {
    okLabbel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func,     // called when ok button is clicked.
    cancel: PropTypes.func,      // called when cancel button is clicked.
    dismiss: PropTypes.func,     // called when backdrop is clicked or escaped.
    enableEscape: PropTypes.bool,
  }
  
  export default confirmable(ConfirmationModal);
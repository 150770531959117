import React from 'react';
import pure from 'recompose/pure';

const FullAccountField = ({ record = {} }) => <span>
    {record.firstName} {record.lastName} &lt;{record.email}&gt;
</span>;


const PureFullAccountField = pure(FullAccountField);

PureFullAccountField.defaultProps = {
    source: 'lastName',
    label: 'Nom',
};

export default PureFullAccountField;
